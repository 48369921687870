$(document).on('turbolinks:load', function(){
	/* author form */

	// new or different photo
	var photoLabel = $('#photo-group label'),
		photoLabelText = photoLabel.text(),
		photoInput = $('#photo-group input'),
		authorPhoto = $('#author-photo');

	photoInput.on('change', function(e){
		var filename = e.target.files.length ? e.target.files[0].name : photoLabelText;
		photoLabel.text(filename);
		if (authorPhoto)
			authorPhoto.hide();
	});

	// international
	var intlCheckbox = $('#author_international'),
		taxFormLabel = $('label[for="author_tax_form_on_file"]');
	intlCheckbox.on('change', function() {
		taxFormLabel.text(this.checked ? 'W-8 on file' : 'W-9 on file');
	});
	intlCheckbox.change();	// execute change event immediately

	// province/state select
	var countrySelect = $('#author_country'),
		provinceStateSelect = $('#author_province_state_select'),
		provinceStateTextField = $('#author_province_state_text');
	countrySelect.on('change', function(){
		if (this.value == 'United States' || this.value == 'Canada') {
			provinceStateSelect.prop('disabled', false);
			provinceStateTextField.prop('disabled', true).val('');
		} else {
			provinceStateSelect.prop('disabled', true).val('');
			provinceStateTextField.prop('disabled', false);
		}
	});
	countrySelect.change();	// execute change event immediately

	// notes fields
	var notesFields = $('#author_notes, #author_flag_notes'),
		notesFieldsDefaultRows;
	notesFields.on('focus', function(){
		notesFieldsDefaultRows = $(this).prop('rows')
		$(this).prop('rows', 10);
	}).on('blur', function(){
		$(this).prop('rows', notesFieldsDefaultRows);
	});
});
